function init(id, redirectUri) {
    this.id             = id;
    this.authHost       = "https://" + this.id + ".nethouse.ru";
    this.signinURL      = "https://" + this.id + ".nethouse.ru/signin";
    this.getUserInfoURL = "https://" + this.id + ".nethouse.ru/api/service/userinfo/userinfo";
    this.kclIframeURL   = "https://accounts.nethouse.ru/auth/realms/nethouse/protocol/openid-connect/login-status-iframe.html";
    this.redirectURI    = redirectUri;
    this.sessIDIframe = {
        origin: "https://" + this.id + ".nethouse.ru"
    };
    this.kclIframe = {
        origin: "https://accounts.nethouse.ru",
    };
}

function getUserInfo() {
    return new Promise(function(resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", this.getUserInfoURL, true);
        xhr.withCredentials = true;
        xhr.send();
        xhr.onload = function () {
            if (xhr && xhr.readyState === 4 && xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                if (!!response) {
                    resolve(response)
                }
            } else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }
        };

        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        }
    }.bind(this));
}

function _getSessionID() {
    return new Promise(function(resolve) {
        var messageCallback = function(event) {
            if (event.origin !== this.sessIDIframe.origin) {
                return
            }

            resolve(event.data);
        }.bind(this);

        window.addEventListener('message', messageCallback);
        this.sessIDIframe.iframe.contentWindow.postMessage('', this.sessIDIframe.origin);
    }.bind(this));
}

function _createSessionIframe() {
    return new Promise(function(resolve) {
        var iframe = document.createElement('iframe');
        iframe.setAttribute('src', this.sessIDIframe.origin + '/signin?in_iframe=1');
        iframe.setAttribute('title', 'nethouse check session iframe');
        iframe.style.display = 'none';
        iframe.onload = function () {
            resolve();
        };
        this.sessIDIframe.iframe = iframe;
        document.body.appendChild(iframe);

    }.bind(this));
}

function checkSSO() {
    if (this.kclIframe.iframe && this.sessIDIframe.iframe) {
        return this._getSessionID().then(function (data) {
            return this._checkSession(data);
        }.bind(this));
    }

    if (!this.kclIframe.iframe && this.sessIDIframe.iframe) {
        return this._getSessionID().then(function (data) {
            return this._createKeycloackSessionIframe().then(function () {
                return this._checkSession(data);
            }.bind(this));
        }.bind(this))
    }

    return this._createSessionIframe().then(function () {
        return this._getSessionID().then(function (data) {
            return this._createKeycloackSessionIframe().then(function () {
                return this._checkSession(data);
            }.bind(this));
        }.bind(this))
    }.bind(this));
}

function _createKeycloackSessionIframe() {
    return new Promise(function(resolve) {
        var iframe = document.createElement('iframe');
        iframe.onload = function () {
            resolve();
        };

        iframe.setAttribute('src', this.kclIframeURL);
        iframe.setAttribute('title', 'keycloack check session iframe');
        iframe.style.display = 'none';
        this.kclIframe.iframe = iframe;
        document.body.appendChild(iframe);
    }.bind(this));
}

function _checkSession(sessionID) {
    return new Promise(function(resolve, reject) {
        var messageCallback = function (event) {
            if (event.origin !== this.kclIframe.origin) {
                return
            }

            if (event.data === "changed") {
                resolve(false);
                return;
            }

            if (sessionID !== "" && event.data === "unchanged") {
                resolve(true);
                return;
            } else if (sessionID === "" && event.data === "unchanged") {
                resolve(false);
                return;
            }

            if (event.data === "error" && sessionID !== "") {
                resolve(false);
                return;
            }

            reject()
        }.bind(this);

        window.addEventListener('message', messageCallback, false);
        this.kclIframe.iframe.contentWindow.postMessage("public-check-sso " + sessionID, this.kclIframe.origin);
    }.bind(this));
}

function signin() {
    if (this.redirectURI) {
        window.location.href = this.signinURL + '?redirect_uri=' + this.redirectURI;
    } else {
        window.location.href = this.signinURL;
    }
}

export {
    init,
    checkSSO,
    getUserInfo,
    signin,
    _createSessionIframe,
    _getSessionID,
    _createKeycloackSessionIframe,
    _checkSession
};
